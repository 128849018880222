body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fixed_button {
  position: fixed;
  bottom: 0px;
  left: calc(92% + -15px);
  border-radius: 50%;
  height: 60px;
  z-index: 1000;
}

.helpInner {
  min-width: 340px;
  z-index: 2100;
  height: 80vh;
  background-attachment: fixed;
  background-image: linear-gradient(to bottom, #458fe1 45%, #ffff 0%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}
.helpInnerView_card {
  transition: all 0.5s ease-in 0s;
  border-radius: 8px;
  /* overflow: hidden auto; */
  padding: 16px 18px;
  margin-right: 15px;
  background: rgb(255, 255, 255);
  margin-bottom: 5.5rem;
}

.helpInner .text_help {
  /* color: #2b68e8; */
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}
.cursor_pointer {
  cursor: pointer;
}

.helpInnerView {
  overflow-y: scroll;
  height: 600px;
}
.helpInnerView .close_icon {
  background-color: rgba(7, 18, 40, 0.4);
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  padding: 5px;
}
.helpInnerView .take_image {
  width: 100%;
  height: 40px;
  color: rgb(18, 52, 77);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 1px solid rgb(207, 215, 223);
  border-radius: 8px;
  background: linear-gradient(rgb(255, 255, 255) 0%, rgb(245, 247, 249) 99.92%);
  box-shadow: rgba(24, 50, 71, 0.05) 0px 1px 0px 0px;
  cursor: pointer;
}
.helpInnerView .upload_file {
  border-radius: 4px;
  background-color: rgb(247, 249, 250);
  border: 1px dashed rgb(172, 182, 190);
  text-align: center;
  padding: 25px 10px;
  cursor: pointer;
}

.helpInnerView::-webkit-scrollbar-thumb {
  border-radius: 5px;
}
.helpInner .upload_file_count {
  font-size: 1rem;
  font-weight: 700;
  color: rgb(115, 117, 139);
  text-transform: uppercase;
  margin-bottom: 5px;
}
.helpInner .upload_file_container {
  border: 1px solid rgb(207, 215, 223);
  border-radius: 4px;
  margin: 10px 0px 0px;
}
.helpInner .upload_file_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  border-bottom: 1px solid rgb(235, 239, 243);
  margin: 0px 15px;
  overflow: hidden;
  padding: 15px;
}
.helpInner .fa-trash {
  color: rgb(115, 117, 139);
  font-size: 20px;
}
.helpInner .fa-trash:hover {
  color: red;
}

.main_card {
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: transparent !important;
}

.notification {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 12px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-content {
  flex: 1;
  color: #333;
}

.notification h3 {
  margin: 0 0 6px;
  font-size: 16px;
  font-weight: 600;
}

.notification p {
  margin: 0;
  font-size: 14px;
}

.take_screen_shot {
  border: rgb(207, 215, 223) 1px solid;
  outline: none;
  height: 40px;
  color: rgb(18, 52, 77);
  font-size: 14px;
  box-shadow: rgba(24, 50, 71, 0.05) 0px 1px 0px 0px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
}
.screen_shot_image {
  height: 170px;
  background-color: skyblue;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.cancel_button_outside {
  position: absolute;
  right: 7px;
  top: 5px;
}

.screen_shot_image .cancel_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(49, 48, 48, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.2px);
  -webkit-backdrop-filter: blur(1.2px);
  cursor: pointer;
}
.icon_color {
  color: rgb(147, 142, 142);
}
.image {
  width: 100%;
  height: 100%;
  border: 8px;
}

.master_success_div {
  height: 600px;
  display: grid;
  grid-template-rows: 45% 55%;
}

.master_success_div .success-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success_message_content {
  display: grid;
  grid-template-rows: 55% 45%;
  text-align: center;
}
.close-btn {
  width: 30px;
  height: 30px;
  background-color: aliceblue;
  border-radius: 4px;
  cursor: pointer;
}
.toaster {
  width: 520px;
}

.phone_number input[type="number"]::-webkit-inner-spin-button,
.phone_number input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.phone_number input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

@keyframes move {
  0% {
    transform: translateY(100px);
  }
}

@media screen and(max-height:700px) {
  .helpInnerView {
    overflow-y: scroll;
    height: 500px;
  }
  .helpInner {
    min-width: 340px;
    z-index: 2100;
    height: 70vh;
    background-attachment: fixed;
    background-image: linear-gradient(to bottom, #458fe1 45%, #ffff 45%);
    background-image: -webkit-linear-gradient(
      to bottom,
      #458fe1 45%,
      #ffff 45%
    );
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: hidden;
  }
}
